import ReactTimeAgo from 'react-time-ago'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { TimeIcon } from 'assets/icons'
import {
	HHMMSSToMs,
	HHMMSSToSec,
	isWeekDifference,
} from 'helpers/dateTimeConfig'
import { getImageProcessorUrl, textParser } from 'helpers'
import { ExtendedMessage } from 'types/chat'
import s from './index.module.scss'

interface Props {
	message: ExtendedMessage
}

const Message = ({ message }: Props): JSX.Element => {
	const navigate = useNavigate()

	const seekToMarker = (): void => {
		const videos = document.querySelectorAll(
			'video[data-scene-video]',
		) as unknown as HTMLVideoElement[]

		const index = [...videos].findIndex(
			(v) => v?.id === 'rolescene_' + message?.content?.playerContentId,
		)
		const video = videos[index]
		if (
			video?.hasAttribute('[data-active=true]') &&
			message?.content?.playerTimestamp
		) {
			video.currentTime = HHMMSSToSec(message.content.playerTimestamp)
		} else {
			navigate(
				window.location.pathname + (window.location.hash?.slice(0, -1) + index),
			)
			if (message?.content?.playerTimestamp)
				video.currentTime = HHMMSSToSec(message.content.playerTimestamp)
		}
	}

	return (
		<div
			className={s.container}
			data-timestamp={
				message.content.playerTimestamp
					? 'time_' + HHMMSSToMs(message.content?.playerTimestamp?.toString())
					: ''
			}>
			<div className={s.header}>
				<div
					className={s.avatar}
					style={{
						border: `1px solid var(--${message?.sender?.CustomerRole?.toLowerCase()})`,
					}}>
					<img
						src={
							getImageProcessorUrl(
								message?.sender?.CustomerImageSquareUrl,
								28,
							) || '/placeholder.png'
						}
						alt={message?.sender?.ContentTitle}
					/>
				</div>
				<span className="body2-m textEllipsis" style={{ marginRight: 'auto' }}>
					{message?.sender?.ContentTitle}
				</span>
				<div className={s.contentWrapper}>
					{message?.content?.contentTitle ? (
						<span style={{ color: 'var(--theme-primary)' }}>
							{message?.content?.contentTitle}
						</span>
					) : null}
					{message.content.playerTimestamp ? (
						<div className={s.time} onClick={seekToMarker}>
							<TimeIcon width="22" height="22" color="var(--theme-primary)" />
							<span
								className="body2-b"
								style={{ color: 'var(--theme-primary)' }}>
								{message.content.playerTimestamp?.toString()}
							</span>
						</div>
					) : null}
				</div>
			</div>
			{textParser(message.content.body)?.map((text) => (
				<span
					className="body2-m"
					style={{ color: 'var(--mono200)' }}
					key={text}>
					{text}
				</span>
			))}
			<span className="caption-m" style={{ color: 'var(--mono400' }}>
				{isWeekDifference(new Date(message.sendTime)) ? (
					dayjs(message.sendTime).format('MM/DD')
				) : (
					<ReactTimeAgo
						date={new Date(message.sendTime)}
						timeStyle="twitter"
						locale="en-US"
					/>
				)}
			</span>
		</div>
	)
}

export default Message
