import dayjs from 'dayjs'
import jwtDecode from 'jwt-decode'
import { getUser, removeUser, updateUserToken } from 'helpers/storage'
import { useAppStore } from 'store'
import { CollectionsType } from 'types/app'

let retryRequest = false

export const getAuthHeaders = (
	token: string | null,
): { [key: string]: string } => ({
	Authorization: `Bearer ${token}`,
	brandid: '6c7c80ee-792e-4ff0-a486-e790582a7260',
})

export const request = async <T = Response>(
	url: string,
	...args: [RequestInit | undefined]
): Promise<T> => {
	const user = getUser()

	const originalRequest = async (newToken: string): Promise<Response> => {
		const headers = args?.length
			? { ...args[0]?.headers, Authorization: `Bearer ${newToken}` }
			: {}
		const newArgs = args?.length ? [{ ...args[0], headers }] : args
		const response = await fetch(url, ...newArgs)

		return response
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onError = async (error: any): Promise<never> => {
		const userToken = user ? user['Custom:User:Token'] : ''
		const {
			exp,
		}: {
			exp: number
		} = jwtDecode(userToken)
		const now = dayjs().unix()
		const refreshToken = user?.['Custom:Refresh:Token']

		if (refreshToken && !retryRequest && now > exp) {
			retryRequest = true
			const { endpoints } = useAppStore.getState()
			const renewUrl = endpoints?.CustomApplicationRenewTokenUrl || ''
			const newTokens = await fetch(renewUrl, {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					'Accept-Charset': 'utf-8',
				},
				method: 'POST',
				body: JSON.stringify([
					{
						Name: 'Custom:User:Token',
						Value: userToken,
					},
					{
						Name: 'Custom:Refresh:Token',
						Value: refreshToken,
					},
					{
						Name: 'Custom:Device:UniqueId',
						Value: localStorage.getItem('Custom:Device:UniqueId'),
					},
				]),
			})

			const tokens = (await newTokens.json()) as CollectionsType

			if (newTokens?.ok && !tokens?.Error) {
				updateUserToken(tokens.Properties)
				retryRequest = false
				const newToken = tokens?.Properties?.find(
					(prop) => prop.Name === 'Custom:User:Token',
				)?.Value
				if (newToken) originalRequest(newToken)
			} else {
				removeUser()
				window.location.pathname = '/join/login'
			}
		}

		return Promise.reject(error.response || error.message)
	}

	const response = await fetch(url, ...args)
	if (!response.ok && [401].includes(response.status))
		await onError('Authorization error')

	return response as T
}

export const fetcher = async <T>(
	url: string,
	...args: [RequestInit | undefined]
): Promise<T> => {
	if (!url) throw new Error('No endpoint')

	const user = getUser()
	const asUserToken =
		useAppStore.getState()?.portfolioMode.asUserData?.accessToken
	const userToken = asUserToken || (user ? user['Custom:User:Token'] : null)

	const arg = args?.length ? args[0] : {}

	const additional = url?.includes('clientreport')
		? {}
		: getAuthHeaders(userToken)

	const headers = {
		'Content-Type': 'application/json',
		Accept: 'application/json',
		'Accept-Charset': 'utf-8',
		...additional,
	}

	const newArgs = [
		{
			...arg,
			headers: {
				...headers,
				...arg?.headers,
			},
		},
	] as [RequestInit | undefined]

	const response = await request(
		url?.replace('.theatroo.', '.showrealz.'),
		...newArgs,
	)

	if ([204, 202].includes(response.status))
		return {
			status: response.status,
			ok: response.ok,
		} as T

	return response.ok
		? response?.url?.includes('QR')
			? await response.blob()
			: await response.json()
		: {}
}

export const multipleFetcher = async <T>(
	urls: string[],
	...args: [RequestInit | undefined]
): Promise<T[]> =>
	await Promise.all<T>(urls.map((url) => fetcher(url, ...args)))

export const fileFetcher = async <T>(
	url: string,
	...args: [RequestInit | undefined]
): Promise<T> => {
	if (!url) throw new Error('No endpoint')

	const user = getUser()
	const userToken = user ? user['Custom:User:Token'] : null

	const arg = args?.length ? args[0] : {}

	const headers = {
		Accept: 'application/json',
		'Accept-Charset': 'utf-8',
		...getAuthHeaders(userToken),
	}

	const newArgs = [
		{
			...arg,
			headers,
		},
	] as [RequestInit | undefined]

	const response = await request(url, ...newArgs)

	if ([204, 202].includes(response.status))
		return {
			status: response.status,
			ok: response.ok,
		} as T

	return response.ok ? await response.json() : {}
}
