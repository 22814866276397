import useSwrImmutable from 'swr/immutable'
import { fetcher } from 'api'
import { FetchReturn } from 'types/app'
import { Marker } from 'types/content'
import { KeyedMutator } from 'swr'
import { useAppStore } from 'store'

type ReturnData = FetchReturn & {
	data: Marker[] | undefined
	refresh: KeyedMutator<{
		pageContent: Marker[]
	}>
}

export default (
	contentId: string | undefined,
	type = 'EasterEgg',
): ReturnData => {
	const { endpoints } = useAppStore.getState()
	const { data, isLoading, error, mutate } = useSwrImmutable<{
		pageContent: Marker[]
	}>(
		contentId
			? endpoints?.CustomApplicationContentMarkerUrl +
					'/Search/' +
					contentId +
					'?type=' +
					type
			: null,
		fetcher,
	)

	return {
		data: data?.pageContent?.length
			? (data?.pageContent as Marker[])
			: undefined,
		isLoading,
		refresh: mutate,
		error: error
			? error instanceof SyntaxError
				? error.message
				: 'something went wrong'
			: undefined,
	}
}
