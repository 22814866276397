import useSwrImmutable from 'swr/immutable'
import { fetcher } from 'api'
import { useAppStore } from 'store'

type Return = {
	isLoading: boolean
	image: Blob | undefined
}

export default (link: string): Return => {
	const { endpoints } = useAppStore.getState()
	const url =
		endpoints?.CustomApplicationCustomerQRCode +
		'/' +
		encodeURIComponent('https://' + window.location.host + link)

	const { data, isLoading } = useSwrImmutable<Blob>(link ? url : null, fetcher)

	return {
		image: data?.type ? data : undefined,
		isLoading,
	}
}
