import { KeyedMutator } from 'swr'
import useSwrImmutable from 'swr/immutable'
import { fetcher, multipleFetcher } from 'api'
import contentParser from 'helpers/propertiesParser'
import { useAppStore } from 'store'
import { CollectionsType, Content, RelationListResponse } from 'types/app'

type ReturnData = {
	isLoading: boolean
	error: string
	collection: Content[] | undefined
	refresh: KeyedMutator<Content[]>
}

export default (content: Content | undefined, bag?: boolean): ReturnData => {
	const { endpoints } = useAppStore.getState()
	const url = content
		? bag
			? endpoints?.CustomApplicationContentPlaylistUrl?.replace(
					'{contentId}',
					content?.ContentId,
			  )
			: content.Actions?.RelationshipContentPlaylist
		: null

	const {
		data: collection,
		error,
		isLoading,
		mutate,
	} = useSwrImmutable<Content[]>(url, (url) => collectionFetcher(url, bag))

	return {
		collection,
		isLoading,
		error,
		refresh: mutate,
	}
}

export const collectionFetcher = async <T>(
	url: string,
	bag?: boolean,
): Promise<T> => {
	const { endpoints } = useAppStore.getState()
	const response = await fetcher<CollectionsType>(url, {})

	const contents = bag
		? ((await multipleFetcher(
				(response as unknown as RelationListResponse)?.pageContent?.map(
					(rel) =>
						endpoints?.CustomApplicationContentUrl +
						'/' +
						rel?.targetId +
						'/PropertyBag',
				),
				{},
		  )) as CollectionsType[])
		: response?.ChildItems
		? ((await multipleFetcher(
				response?.ChildItems?.map((child) => child.Actions[0].Url),
				{},
		  )) as CollectionsType[])
		: []

	const parsed = contents
		?.filter((c) => c?.Properties)
		?.map((child) => {
			const content = contentParser(child)
			const relationIndex = response?.ChildItems?.find((c) => {
				const parsed = contentParser(c)

				return parsed?.ContentId === content?.ContentId && parsed?.ContentIndex
			})?.Properties?.find((p) => p.Name === 'Content:Index')?.Value

			return {
				...content,
				relationIndex,
			}
		}) as T

	return parsed
}
