export type UserActions = {
	SubscriptionRetrieveExternalIds: string
	SubscriptionUpdateExternalIds: string
	RelationshipCustomerLike: string
	RelationshipCustomerDislike: string
	RelationshipCustomerUpvote: string
	RelationshipCustomerDownvote: string
	RelationshipCustomerBookmark: string
	RelationshipCustomerPlaylist: string
	RelationshipCustomerPlayback: string
	RelationshipCustomerRating: string
	RelationshipCustomerReview: string
	RelationshipCustomerFullPlay: string
	RelationshipCustomerFollow: string
	RelationshipCustomerEvent: string
	RelationshipCustomerOrganization: string
	RelationshipCustomerContent: string
	RelationshipCustomerContest: string
	RelationshipCustomerFavContent: string
	RelationshipCustomerFavCustomer: string
	RelationshipCustomerFavCatalog: string
	RelationshipCustomerProject: string
	RelationshipCustomerBanDiscussion: string
	RelationshipCustomerPromos: string
	RelationshipCustomerHighlights: string
	RelationshipCustomerContracts: string
	RelationshipCustomerInvitedCreators: string
	RelationshipCustomerPermanentCreators: string
	RelationshipCustomerAwards: string
	RelationshipCustomerHistory: string
	RelationshipCustomerUnfinished: string
	RelationshipCustomerPosition: string
	RelationshipCustomerBestPerformance: string
	RelationshipCustomerImage: string
}
export type User = {
	Actions?: UserActions
	CustomUserId: string
	ContentCreatedDate: string
	CustomerSettingsTutorialLanguage: string
	ContentId: string
	CustomUserPhoneNumber: string
	CustomUserBiography: string
	ContentTitle: string
	CustomerSettingsApplicationLanguage: string
	CustomerSettingsTutorialAgent: string
	CustomerImageLandscapeUrl: string
	CustomerImagePortraitUrl: string
	CustomerImageSquareUrl: string
	CustomGeoLocation: string
	CustomUserAvatar: string
	CustomUserEmail: string
	CustomerPortfolioUpdated: string
	CustomUserGender: string
	CustomUserUserName: string
	CustomerPortfolioCreated: string
	CustomerPoolDefault: string
	CustomerChatRoom: string
	CustomerChatId: string
	CustomerPortfolioReadiness: string
	CustomerImdbUrl: string
	CustomerAgencyId: string
	CustomerAgencyStatus: string
	CustomerValidateStatus: string
	CustomerAppleWalletUrl: string
	CustomerGeoLocation: string
	CustomerLocationCountry: string
	CustomerLocationCountryCode: string
	CustomerLocationCity: string
	CustomerGoogleWalletUrl: string
	CustomerAgencyAccess: string
	CustomerType: string
	CustomerStatInviteNumberOfInvitationTotal: string
	CustomerStatViewed7Day: string
	CustomerStatViewed30Day: string
	CustomerStatMembershipTotal: string
	CustomerStatMembership7Day: string
	CustomerStatProjectTotal: string
	CustomerStatProject7Day: string
	CustomerStatProject30Day: string
	CustomerStatAuditionessTotal: string
	CustomerStatAuditioness30Day: string
	CustomerStatAuditioness7Day: string
	CustomerStatInvitationTotal: string
	CustomerStatInvitation30Day: string
	CustomerStatInvitation7Day: string
	CustomerStatRolesTotal: string
	CustomerStatRoles7Day: string
	CustomerStatRoles30Day: string
	CustomerLabelLocation: string
	CustomerName: string
	CustomerProfession: string
	CustomUserBirthDate: string
	CustomerRole: string
	CustomerUserPhoneNumber: string
	CustomUserGenre: string
	CustomUserSexuality: string
	CustomUserRace: string
	CustomUserDisability: string
	CustomerNationality: string
	CustomerAgency: string
	CustomerDistance: string
	CustomerAge: string
	CustomerLanguage: string
	CustomerProcessStatus: string
	CustomerPhotoPinned: string
	CustomerShowreelPinned: string
	CustomerStatAudiencesTotal: string
	CustomerStatAudiences7Day: string
	CustomerStatAudiences30Day: string
	empty?: boolean
	relationExtraData?: string
}

export enum CUSTOMER_ROLE {
	ACTOR = 'actor',
	CASTING_DIRECTOR = 'castingdirector',
	AGENCY = 'agency',
	// CONTRIBUTOR = 'contributor',
}

export type UserData = {
	'Custom:User:Id': string
	'Custom:User:Name': string
	'Custom:User:Token': string
	'Custom:User:Avatar': string
	'Custom:User:Email': string
	'Custom:Refresh:Token': string
	'Custom:Token:Expiration': string
}

export type AuthenticateAs = {
	accessToken: string
	avatarImageUrl: string
	correlationId: string
	customerId: string
	defaultLocale: string
	emailAddress: string
	expireAt: string
	refreshToken: string
	username: string
}
