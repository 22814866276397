import { useShallow } from 'zustand/react/shallow'
import { useState } from 'react'
import PhoneInput from 'components/UI/PhoneInput'
import Sidebar from 'components/Sidebar'
import CountrySelector from 'components/CountrySelector'
import Input from 'components/UI/Input'
import Select from 'components/UI/Select'
import { optionsParser, t } from 'helpers'
import { useAppStore } from 'store'
import { DROPDOWN_OPTIONS } from 'types/enums'
import { NetworkCardType } from 'types/app'
import { notif } from 'helpers/notif'

const properties = ['name', 'email', 'phone', 'location', 'city', 'gender']

const AddUserSidebar = (): JSX.Element => {
	const [openSidebar, onAdd, setOpenSidebar] = useAppStore(
		useShallow((state) => [
			state.addUserContactSidebar.open,
			state.addUserContactSidebar?.onAdd,
			state.setUserContactSidebar,
		]),
	)
	const [values, setValues] = useState<NetworkCardType>(
		properties?.reduce(
			(prev, next) => ({
				...prev,
				[next]: '',
			}),
			{},
		) as NetworkCardType,
	)

	const addUser = (): void => {
		if (values?.email && values?.name) {
			if (onAdd) onAdd(values)
			setOpenSidebar({
				open: false,
				onAdd: null,
			})
			setValues(
				properties?.reduce(
					(prev, next) => ({
						...prev,
						[next]: '',
					}),
					{},
				) as NetworkCardType,
			)
		} else notif('info', t('APP_FILL_ALL_FIELDS'))
	}

	const onChange = (v: string, name: string): void => {
		setValues((prev) => ({
			...prev,
			[name]: v,
		}))
	}

	return (
		<Sidebar
			open={openSidebar}
			additional
			onClose={() =>
				setOpenSidebar({
					open: false,
					onAdd: null,
				})
			}
			header={{
				title: 'APP_NETWORK_ADD_USER_SIDEBAR_TITLE',
				description: 'APP_NETWORK_ADD_USER_SIDEBAR_DESCRIPTION',
			}}
			buttons={[
				{
					onClick: addUser,
					children: t('APP_ADD_USER_BUTTON'),
				},
			]}>
			<>
				{properties?.map((name) =>
					name === 'location' ? (
						<CountrySelector
							key={name}
							value={values[name] || ''}
							onChange={(e) => onChange(e.target.value, name)}
						/>
					) : name === 'phone' ? (
						<PhoneInput
							key={name}
							label={`APP_${name?.toUpperCase()}_LABEL`}
							defaultValue={values[name as keyof NetworkCardType] || ''}
							onBlur={(e) => onChange(e.target.value, name)}
						/>
					) : name === 'gender' || name === 'roleType' ? (
						<Select
							key={name}
							onChange={(e) => onChange(e.target.value, name)}
							name={name}
							label={`APP_${name?.toUpperCase()}_LABEL`}
							inputProps={{
								value: values[name],
							}}
							options={optionsParser(
								name === 'gender'
									? DROPDOWN_OPTIONS.GENDERS
									: DROPDOWN_OPTIONS.ROLE_TYPE,
							)}
						/>
					) : (
						<Input
							key={name}
							label={`APP_${name?.toUpperCase()}_LABEL`}
							value={values[name as keyof NetworkCardType] || ''}
							onChange={(e) => onChange(e.target.value, name)}
						/>
					),
				)}
			</>
		</Sidebar>
	)
}

export default AddUserSidebar
