import {
	ChangeEvent,
	InputHTMLAttributes,
	useMemo,
	useRef,
	useState,
} from 'react'
import cn from 'classnames'
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup'
import { ArrowShortIcon, PlusIcon } from 'assets/icons'
import Input from 'components/UI/Input'
import inputStyle from 'components/UI/Input/index.module.scss'
import { useOutsideClick } from 'hooks'
import { t } from 'helpers'
import { filterDuplicates } from 'helpers/propertiesParser'
import { SelectOption } from 'types/app'
import s from './index.module.scss'

interface Props {
	error?: string
	options: SelectOption[]
	label?: string
	name: string
	inputProps?: InputHTMLAttributes<HTMLInputElement>
	sort?: 'abc' | 'none'
	onChange: (e: ChangeEvent<HTMLInputElement>) => void
	searchable?: boolean
}

const Select = ({
	options,
	error,
	inputProps,
	label,
	name,
	sort,
	searchable = true,
	onChange,
}: Props): JSX.Element => {
	const anchor = useRef<HTMLDivElement>()
	const [showOptions, setShowOptions] = useState(false)
	const [searchValue, setSearchValue] = useState('')

	const selectRef = useRef<HTMLDivElement>(null)
	const popupRef = useRef<HTMLDivElement>(null)

	useOutsideClick(popupRef, () => {
		setShowOptions(false)
	})

	const selectedOption = useMemo(
		() => options?.find((o) => o.value === inputProps?.value),
		[options, inputProps?.value],
	)

	const inputValue = useMemo(
		() =>
			((selectedOption
				? t(selectedOption?.label)
				: inputProps?.value) as string) || '',
		[selectedOption, inputProps?.value],
	)

	const props = searchable
		? {
				// ...inputProps,
				value: inputValue,
				onChange: (e: ChangeEvent<HTMLInputElement>) => {
					if (!e.target.value)
						onChange({
							target: {
								name: inputProps?.name,
								value: '',
							},
						} as ChangeEvent<HTMLInputElement>)
					setSearchValue(e.target.value)
				},
				name,
		  }
		: {
				...inputProps,
				value: inputValue,
				onChange,
				name,
		  }

	const parsedOptions = useMemo(
		() =>
			filterDuplicates<SelectOption>(options, 'value')
				.filter((option) =>
					searchable
						? option?.label?.toLowerCase().includes(searchValue?.toLowerCase())
						: option,
				)
				.sort(
					sort === 'abc' ? (a, b) => a.label.localeCompare(b.label) : undefined,
				),
		[options, searchValue, sort, searchable],
	)

	return (
		<div className={s.container} ref={selectRef}>
			<div className={s.fieldWrapper}>
				<Input
					{...props}
					error={error}
					label={label}
					value={searchValue || props?.value}
					startAdornment={selectedOption?.element}
					style={{ paddingRight: '55px' }}
					disabled={inputProps?.disabled}
					placeholder={inputProps?.placeholder}
				/>
				<div
					className={cn(inputStyle.input, s.handler)}
					onClick={(e) => {
						if (inputProps?.disabled) return

						anchor.current = e.currentTarget
						setShowOptions((prev) => !prev)
						if (searchable) {
							const target = e.target as HTMLElement
							const input = target.parentNode?.children[0]
								?.children[1] as HTMLInputElement
							input?.focus()
							input?.select()
						}
					}}></div>
				{selectedOption ? (
					<div
						className={s.reset}
						onClick={() => {
							if (inputProps?.disabled) return

							onChange({
								target: {
									name: inputProps?.name,
									value: '',
								},
							} as ChangeEvent<HTMLInputElement>)
							setSearchValue('')
						}}
						style={
							!label
								? { top: '50%', transform: 'translateY(-50%)', bottom: '0' }
								: {}
						}>
						<PlusIcon
							color="var(--mono300)"
							style={{
								transform: 'rotate(45deg)',
							}}
						/>
					</div>
				) : null}
				<div
					className={s.arrow}
					style={
						!label
							? {
									top: '50%',
									transform: 'translateY(-50%)',
									bottom: '0',
									marginBottom: showOptions ? '-2px' : '1px',
							  }
							: { marginBottom: showOptions ? '-2px' : '1px' }
					}>
					<ArrowShortIcon
						color="var(--mono300)"
						style={{
							transform: showOptions ? 'rotate(-90deg)' : 'rotate(90deg)',
						}}
					/>
				</div>
			</div>

			{selectedOption?.hint ? (
				<span
					className="caption-m inputLabel"
					style={{
						color: 'var(--mono300)',
						textTransform: 'none',
						paddingBottom: '0px',
					}}>
					{selectedOption.hint}
				</span>
			) : null}
			{showOptions ? (
				<BasePopup
					id="select-popup"
					open
					ref={popupRef}
					anchor={anchor.current}
					style={{
						zIndex: 'var(--selectPopupIndex)',
						top: '5px',
						width: selectRef?.current?.offsetWidth + 'px',
					}}>
					<div className={cn(s.options, 'hideScrollBar')}>
						{parsedOptions?.map((option) => (
							<div
								key={option.value}
								className={s.option}
								onClick={() => {
									setSearchValue('')
									onChange({
										target: {
											name: name || inputProps?.name,
											value: option.value,
										},
									} as ChangeEvent<HTMLInputElement>)
									setTimeout(() => {
										setShowOptions(false)
									}, 200)
								}}>
								<div className={s.labelWrapper}>
									{option?.element}
									<span
										className="body2-m textEllipsis"
										style={{ marginLeft: option?.element ? '10px' : '0px' }}>
										{t(option.label)}
									</span>
									{option?.color ? (
										<div
											className={s.color}
											style={{ backgroundColor: option.color }}
										/>
									) : null}
								</div>
								{option?.hint ? (
									<span className={cn('caption-r', s.hint)}>{option.hint}</span>
								) : null}
							</div>
						))}
					</div>
				</BasePopup>
			) : null}
		</div>
	)
}

export default Select
