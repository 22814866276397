import useSwrImmutable from 'swr/immutable'
import { fetcher } from 'api'
import { propertiesParser, actionsParser } from 'helpers/propertiesParser'
import { useAppStore } from 'store'
import { ContentCollection, FetchReturn } from 'types/app'
import { User } from 'types/user'

type ReturnData = FetchReturn & {
	user: User | undefined
}

export default (id: string | undefined | null, bag?: boolean): ReturnData => {
	const { endpoints } = useAppStore.getState()

	const cacheUrl = id
		? endpoints?.CustomApplicationV3CustomerUrl.replace('${customerId}', id)
		: null
	const bagUrl = id
		? endpoints?.CustomApplicationCustomerV2 + '/' + id + '/PropertyBag'
		: null

	const customerUrl = bag ? bagUrl : cacheUrl

	const { data, error, isLoading } = useSwrImmutable<ContentCollection>(
		customerUrl ? customerUrl : null,
		fetcher,
	)

	const userData =
		data &&
		data.Properties &&
		({
			...propertiesParser(data.Properties),
			Actions: actionsParser(data.Actions),
		} as User)

	return {
		isLoading: isLoading,
		error: error
			? error instanceof SyntaxError
				? error.message
				: 'something went wrong'
			: undefined,
		user: userData,
	}
}
