import { useShallow } from 'zustand/react/shallow'
import { t } from 'helpers'
import { useUserStore } from 'store'
import { CUSTOMER_ROLE } from 'types/user'
import s from './index.module.scss'

type DataType = {
	value: string
	label: string
	changeValue: string
	changeLabel: string
}

const KPI = (): JSX.Element => {
	const [user] = useUserStore(useShallow((state) => [state.user]))
	const userRole = useUserStore(
		useShallow((state) => state.user?.CustomerRole?.toLowerCase()),
	)

	const actorData: DataType[] = [
		{
			value: user?.CustomerStatViewed7Day || '0',
			label: 'APP_KPI_PROFILE_VIEWS',
			changeLabel: 'APP_KPI_PERIOD_LASTWEEK',
			changeValue: '',
		},
		{
			value: user?.CustomerStatInviteNumberOfInvitationTotal || '0',
			label: 'APP_KPI_INVITATIONS',
			changeLabel: '',
			changeValue: '',
		},
	]

	const cdData: DataType[] = [
		{
			value: user?.CustomerStatMembershipTotal || '0',
			label: 'APP_KPI_MEMBERS',
			changeLabel: 'APP_KPI_PERIOD_LASTWEEK',
			changeValue: '',
			// changeValue: user?.CustomerStatMembership7Day || '',
		},
		{
			value: user?.CustomerStatProjectTotal || '0',
			label: 'APP_KPI_PROJECTS',
			changeLabel: 'APP_KPI_PERIOD_LASTMONTH',
			changeValue: '0',
			// changeValue: user?.CustomerStatProject30Day || '0',
		},
		{
			value: user?.CustomerStatRolesTotal || '0',
			label: 'APP_KPI_ROLES',
			changeLabel: 'APP_KPI_PERIOD_LASTWEEK',
			changeValue: '',
			// changeValue: user?.CustomerStatRoles7Day || '',
		},
		{
			value: user?.CustomerStatInvitationTotal || '0',
			label: 'APP_KPI_INVITATIONS',
			changeLabel: 'APP_KPI_PERIOD_LASTMONTH',
			// changeValue: user?.CustomerStatInvitation30Day || '',
			changeValue: '',
		},
		{
			value: user?.CustomerStatAuditionessTotal || '0',
			label: 'APP_KPI_AUDITIONESS',
			changeLabel: 'APP_KPI_PERIOD_LASTWEEK',
			changeValue: '',
			// changeValue: user?.CustomerStatAuditioness7Day || '',
		},
	]

	const agencyData: DataType[] = [
		{
			value: user?.CustomerStatMembershipTotal || '0',
			label: 'APP_KPI_MEMBERS',
			changeLabel: 'APP_KPI_PERIOD_LASTWEEK',
			changeValue: '',
			// changeValue: user?.CustomerStatMembership7Day || '',
		},
		{
			value: user?.CustomerStatInviteNumberOfInvitationTotal || '0',
			label: 'APP_KPI_INVITATIONS',
			changeLabel: 'APP_KPI_PERIOD_LASTWEEK',
			changeValue: '',
		},
		{
			value: user?.CustomerStatAudiencesTotal || '0',
			label: 'APP_KPI_AUDITIONESS',
			changeLabel: 'APP_KPI_PERIOD_LASTWEEK',
			changeValue: '',
			// changeValue: user?.CustomerStatAudiences7Day || '',
		},
	]

	const values: { [key in CUSTOMER_ROLE]: DataType[] } = {
		[CUSTOMER_ROLE.ACTOR]: actorData,
		[CUSTOMER_ROLE.CASTING_DIRECTOR]: cdData,
		[CUSTOMER_ROLE.AGENCY]: agencyData,
	}

	const data = values[userRole as CUSTOMER_ROLE]

	return (
		<div className={s.wrapper}>
			<div className={s.dataWrapper}>
				{data?.map((item) => (
					<Item item={item} key={item.value + item.label} />
				))}
			</div>
		</div>
	)
}

const Item = ({ item }: { item: DataType }): JSX.Element => {
	return (
		<div className={s.dataItem}>
			<h3>{item.value}</h3>
			<span className="caption-s" style={{ color: 'var(--mono200)' }}>
				{t(item.label)}
			</span>
			{item?.changeValue && item.changeValue !== '0' ? (
				<div className={s.change}>
					<span
						className="caption-r"
						style={{ color: 'var(--mono400)', fontSize: '11px' }}>
						{item.changeValue}
					</span>
					<span
						className="caption-r"
						style={{
							fontSize: '11px',
							color: 'var(--mono400)',
						}}>
						{t(item.changeLabel)}
					</span>
				</div>
			) : null}
		</div>
	)
}

export default KPI
